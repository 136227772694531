.TopImage {
    margin-top: 100px;
    width: 50%;
    transition: opacity 0.3s;
    &:hover {
      opacity: 0.8;
    }
}

/* TODO: アンカーを調整したい */
/* a.anchor{
  display: block;
  padding-top: 10px;
  margin-top: -10px;
} */